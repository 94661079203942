.navbar-default {
  text-align: center;
  background: lightblue;
}

nav {
    z-index: 9999;
}
.center-navbar .navbar {
  display: inline-block;
}

.center-navbar .container {
  width: 100%;
}

td{
  border:1px solid #ccc;
}

.ButtonBlock{
  background-color: #f6d198;
  margin-right: 10px;
}

.Form1{
  background-color: #9cbca5;
  margin: 3px;
  z-index: 7;
}
.FormLogin{
}
.form-label{
  background-color: #9cbca5;
  margin: 2px;
}
.MasterTitle{
  background-color: #f6d198;
  font-size: 20px;
}

.master{
  background-color: #f0f0f0;

}

.FormFooter{
  align-items: right;
  background-color: #f0f0f0;
  height: 30px;
  margin: 1px;
}

/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: right;
  align-items: right;
  font-size: small;
  width: 33.33%;
  max-height: 100vh;
}
.row h3 {font-size: 10px;}
/* Clear floats after image containers */
.row:before{
  content: "";
  font-size: 12pt;
  clear: both;
  display: table;
  font-size-adjust: 0.05;
}

html {
  height: 100%;
  background-color: #ae9e66;
}
* {
  box-sizing: border-box;
}
body {
  background-color: #ae9e66;
}

/* Login Screen Design  */
.bg-img {
  z-index: 0;
  position: relative;
  overflow: hidden;
  /* Center and scale the image nicely */
  background-position: center;
  margin-left: 0.1px;
  margin-top: 10px;
  /* Control the height of the image */
  min-height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 50rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 168, 76, 0.6)), to(rgba(255, 123, 13, 0.6))), url("../imgs/img_nature_wide.jpg");
  background-image: linear-gradient(to bottom, rgba(255, 168, 76, 0.6) 0%, rgba(255, 123, 13, 0.6) 100%), url("../imgs/img_nature_wide.jpg");
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center center;
  padding: 12rem;
  border-style: solid;
}


/* Add styles to the form container */
.container {
  z-index: 5;
  position: absolute;
  min-width: 350px;
  max-width: 350px;
  padding: 16px;
}

/* Full-width input fields */
  input[type=text], input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit button */
.btn {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}
.btn-popup{
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  margin: 10px;
  border: solid;
  cursor: pointer;
  width: 30%;
  opacity: 0.9;
}

.btn-label{
  background-color: #4CAF50;
  color: white;
  padding: 16px 1px;
  margin: 1px;
  border: solid;
  cursor: pointer;
  width: 16%;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  z-index: 10;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 10;
}

.bird {
  background-image: url(../imgs/bird-cells.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
  animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
  animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bird--one {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.bird--two {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.bird--three {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.bird--four {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.bird-container {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: -7.5vw;
  -webkit-transform: scale(0);
  transform: scale(0);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
  animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bird-container--one {
  z-index: 1;
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.bird-container--two {
  z-index: 1;
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.bird-container--three {
  z-index: 1;
  -webkit-animation-duration: 14.6s;
  animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}
.bird-container--four {
  z-index: 1;
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
  animation-delay: 10.25s;
}
@-webkit-keyframes fly-cycle {
100% {
background-position: -900px 0;
}
}
@keyframes fly-cycle {
100% {
background-position: -900px 0;
}
}
@-webkit-keyframes fly-right-one {
0% {
left: -10%;
-webkit-transform: scale(0.3);
transform: scale(0.3);
}
10% {
left: 10%;
-webkit-transform: translateY(2vh) scale(0.4);
transform: translateY(2vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(0vh) scale(0.5);
transform: translateY(0vh) scale(0.5);
}
30% {
left: 50%;
-webkit-transform: translateY(4vh) scale(0.6);
transform: translateY(4vh) scale(0.6);
}
40% {
left: 70%;
-webkit-transform: translateY(2vh) scale(0.6);
transform: translateY(2vh) scale(0.6);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
60% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
100% {
left: 110%;
opacity: 1;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
}
@keyframes fly-right-one {
0% {
left: -10%;
-webkit-transform: scale(0.3);
transform: scale(0.3);
}
10% {
left: 10%;
-webkit-transform: translateY(2vh) scale(0.4);
transform: translateY(2vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(0vh) scale(0.5);
transform: translateY(0vh) scale(0.5);
}
30% {
left: 50%;
-webkit-transform: translateY(4vh) scale(0.6);
transform: translateY(4vh) scale(0.6);
}
40% {
left: 70%;
-webkit-transform: translateY(2vh) scale(0.6);
transform: translateY(2vh) scale(0.6);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
60% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
100% {
left: 110%;
opacity: 1;
-webkit-transform: translateY(0vh) scale(0.6);
transform: translateY(0vh) scale(0.6);
}
}
@-webkit-keyframes fly-right-two {
0% {
left: -10%;
opacity: 1;
-webkit-transform: translateY(-2vh) scale(0.5);
transform: translateY(-2vh) scale(0.5);
}
10% {
left: 10%;
-webkit-transform: translateY(0vh) scale(0.4);
transform: translateY(0vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(-4vh) scale(0.6);
transform: translateY(-4vh) scale(0.6);
}
30% {
left: 50%;
-webkit-transform: translateY(1vh) scale(0.45);
transform: translateY(1vh) scale(0.45);
}
40% {
left: 70%;
-webkit-transform: translateY(-2.5vh) scale(0.5);
transform: translateY(-2.5vh) scale(0.5);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
51% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
100% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
}
@keyframes fly-right-two {
0% {
left: -10%;
opacity: 1;
-webkit-transform: translateY(-2vh) scale(0.5);
transform: translateY(-2vh) scale(0.5);
}
10% {
left: 10%;
-webkit-transform: translateY(0vh) scale(0.4);
transform: translateY(0vh) scale(0.4);
}
20% {
left: 30%;
-webkit-transform: translateY(-4vh) scale(0.6);
transform: translateY(-4vh) scale(0.6);
}
30% {
left: 50%;
-webkit-transform: translateY(1vh) scale(0.45);
transform: translateY(1vh) scale(0.45);
}
40% {
left: 70%;
-webkit-transform: translateY(-2.5vh) scale(0.5);
transform: translateY(-2.5vh) scale(0.5);
}
50% {
left: 90%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
51% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
100% {
left: 110%;
-webkit-transform: translateY(0vh) scale(0.45);
transform: translateY(0vh) scale(0.45);
}
}